// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authorityUrl: 'https://login.microsoftonline.com/254eb027-2c59-49d3-90f7-0e68f7a207aa',
  clientId: 'a4daf1b4-dfae-4b03-b640-d8380a36fd8f',
  redirect_uri: 'http://localhost:4242/signin-callback',
  post_logout_redirect_uri: 'http://localhost:4242/signout-callback',
  silent_redirect_uri: 'http://localhost:4242/signin-silent-callback',
  identifier: 'https://api.partnercenter.microsoft.com',
  api: 'https://api.partnercenter.microsoft.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
