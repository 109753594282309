import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

  constructor(
    private _authService: AuthService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this._isAuthorized(state.url);
  }

  canLoad(route: Route): Promise<boolean> {
    return this._isAuthorized(route.path);
  }

  private async _isAuthorized(url: string) {
    const isAuthorized = await this._authService.isAuthorized();

    if (isAuthorized) {
      return true;
    }

    console.log('User is not logged in -> start authentication flow');
    await this._authService.signin(url);

    return false;
  }
}
