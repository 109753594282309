import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CONFIG } from '@shared/helper/injection-tokens/config.injection-token';
import { UserManager } from 'oidc-client';

import { userManagerFactory } from './factories/user-manager.factory';
import { TokenInterceptor } from './helper/token.interceptor';
import { AuthService } from './services/auth.service';

@NgModule({
  imports: [
    CommonModule
  ], providers: [
    { provide: UserManager, useFactory: userManagerFactory, deps: [CONFIG] },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true, deps: [AuthService] }
  ]
})
export class AuthModule { }
