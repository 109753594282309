import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './shared/auth/guards/auth.guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/report-page/report-page.module').then(m => m.ReportPageModule),
        canActivate: [AuthGuard]
    }, {
        path: 'signin-callback',
        loadChildren: () => import('./pages/auth/signin-callback-page/signin-callback-page.module').then(m => m.SigninCallbackPageModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
