import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingOverlayModule } from '@mkollers/loading-overlay';
import { AuthModule } from '@shared/auth/auth.module';
import { DataAccessModule } from '@shared/data-access/data-access.module';
import { LayoutModule } from '@shared/layout/layout.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // Material

    // Custom
    AuthModule,
    DataAccessModule,
    LayoutModule,

    // 3rd-Party
    LoadingOverlayModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
