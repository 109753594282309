import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Profile, UserManager } from 'oidc-client';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
  deps: [UserManager]
})
export class AuthService {
  profile$ = new BehaviorSubject<Profile>(null);

  constructor(
    private _manager: UserManager,
    router: Router
  ) {
    this._manager.events.addAccessTokenExpiring(() => this._manager.signinSilent());
    this._manager.events.addAccessTokenExpired(() => this.signin(router.url));
    this._manager.events.addUserLoaded(user => this.profile$.next(user.profile));

    this._initUser();
  }

  /**
   * Loads the user from the localstorage. If there is a valid token, the user will be loaded inside the library, which triggers the
   * addUserLoaded-Event, which sets the value inside the profile$ Observable which can be used to display the current user.
   */
  private _initUser = async () => {
    const user = await this.getUser();
    if (user) {
      this._manager.events.load(user);
    }
  }

  completeSignin = () => this._manager.signinRedirectCallback().then(u => u?.state);
  completeSignout = () => this._manager.signoutRedirectCallback();
  getUser = () => this._manager.getUser();
  isAuthorized = () => this.getUser().then(u => u?.expired === false);
  signin = (state?: any) => this._manager.signinRedirect({ state });
  signout = () => this._manager.signoutRedirect();
}
