<mat-toolbar>
  <a routerLink="/">
    <svg xmlns="http://www.w3.org/2000/svg" id="Logo" viewBox="0 0 1280 260">
      <path id="r_1_" fill="currentColor"
        d="M1243.3 236.5c-9.2 0-16.6-7.4-16.6-16.6 0-9.2 7.4-16.6 16.6-16.6 9.2 0 16.7 7.4 16.7 16.6 0 9.2-7.5 16.6-16.7 16.6zm0-30.7c-7.8 0-14 6.4-14 14.2 0 7.8 6.2 14 14 14s14.1-6.2 14.1-14c.1-7.9-6.3-14.2-14.1-14.2zm7.1 24.4h-1.7c-.3 0-.6-.2-.7-.5l-5-7.9-.7-.2h-2.5v7.7c0 .4-.4.9-.9.9h-1.5c-.5 0-.8-.5-.8-.9v-18.7c0-.8.4-1.3 1.1-1.4.9-.1 3.3-.2 4.7-.2 5.1 0 8.1 1.5 8.1 6.2v.2c0 3.1-1.5 4.8-4.1 5.6l4.8 7.7c.1.1.1.4.1.6-.2.5-.4.9-.9.9zm-3.1-14.9c0-2.5-1.4-3.3-4.8-3.3h-2.9v6.9c.7 0 2.4.1 2.8.1 3.5 0 4.8-.9 4.8-3.4v-.3z" />
      <path id="l" fill="currentColor"
        d="M1132.2 23.5a7.6 7.6 0 00-7.6 7.6v149.3c0 50.9 17.7 60.4 60.6 60.4 8.4 0 16.7-1.1 25.8-2.7 4.9-.8 7.2-3 7.2-6.8v-23.9c0-3.8-3-5.7-8-5.7h-20.1c-13.7 0-15.6-2.3-15.6-21.3V31.1c0-4.2-3.4-7.6-7.6-7.6" />
      <path id="a_1_" fill="currentColor"
        d="M990.6 229.7c-1.7 3.6.2 6.5 4.1 6.5h37.4c3.9 0 5.8-2.9 4.1-6.5l-18.9-40.7c-1.7-3.6-6.5-3.1-8.2.5l-18.5 40.2z" />
      <path id="a" fill="currentColor"
        d="M924 236.2h38c3.4 0 6.8-2.7 8-7.6l39-153.8c1.5-7.2 1.7-9.5 4.7-9.5s3.2 2.3 4.7 9.5l39 153.8c1.1 4.9 4.2 7.6 7.6 7.6h38c3.4 0 6.1-2.7 6.1-6.1 0-.8 0-1.9-.4-2.7l-57.1-182.7c-3.6-12.9-18.5-25.5-37.9-25.5s-34.9 13.2-38.4 25.5l-57 182.7c-.4.8-.4 1.9-.4 2.7 0 3.4 2.7 6.1 6.1 6.1z" />
      <path id="c" fill="currentColor"
        d="M846.3 63.4c24.7 0 33.8.8 49 2.7h1.1c3.8 0 6.8-1.9 6.8-5.7V35.6c0-6.1-1.5-8.4-8-9.9-8.7-2.3-25.8-4.9-49-4.9-49.4 0-86.6 30.4-86.6 92.3v35.3c0 61.9 37.2 92.3 86.6 92.3 23.2 0 40.3-2.7 49-4.9 6.5-1.5 8-3.8 8-9.9v-24.7c0-3.8-3-5.7-6.8-5.7h-1.1c-15.2 1.9-24.3 2.7-49 2.7-17.9 0-36.5-10.3-36.5-49.8v-35.3c.1-39.5 18.7-49.7 36.5-49.7z" />
      <path id="i" fill="currentColor"
        d="M732.6 23.5h-34.9a7.6 7.6 0 00-7.6 7.6v198.7c0 4.2 3.4 8 7.6 8h34.9c4.2 0 7.6-3.8 7.6-8V31.1c0-4.2-3.4-7.6-7.6-7.6z" />
      <path id="t" fill="currentColor"
        d="M656.8 23.5c4.2 0 7.6 3.4 7.6 7.6v24.6c0 4.2-3.4 7.6-7.6 7.6h-34.2v117.1c0 19 1.1 21.3 14.8 21.3h20.1c4.9 0 8 1.9 8 5.7v23.9c0 3.8-2.3 6.1-7.2 6.8-9.1 1.5-17.5 2.7-25.8 2.7-42.9 0-60.4-9.5-60.4-60.4V63.3H544c-4.2-.8-7.6-3.9-7.6-8V31.1c0-4.2 3.4-7.6 7.6-7.6h112.8z" />
      <path id="r" fill="currentColor"
        d="M513.6 23.3c-15.2 0-28.1 1.3-46.8 15.7v-8c0-4.2-3.4-7.6-7.6-7.6h-33.1a7.6 7.6 0 00-7.6 7.6v198.7c0 4.2 3.4 8 7.6 8H461c4.2 0 7.6-3.8 7.6-8V77c16-8.4 24.3-13.7 44.9-13.7 4.2 0 7.6-3.4 7.6-7.6V30.9c.1-4.1-3.4-7.6-7.5-7.6z" />
      <path id="e" fill="currentColor"
        d="M306.1 20.8c-58.1 0-91.2 37.2-91.2 96.5v28.1c0 74.8 53.9 95.4 91.9 95.4 34.2 0 49-1.9 73.7-6.5 6.8-1.1 8-3.8 8-9.9v-25.1c0-3.8-3-5.7-6.8-5.7h-1.1c-16 1.5-42.2 3.8-69.9 3.8-36.5 0-45.6-26.2-45.6-48.2v-1.1h123.8c5.3 0 7.6-3.4 7.6-9.5v-21.3c0-56.6-31.9-96.5-90.4-96.5zm39.9 91.6h-80.5v-1.5c0-30 17.1-46.7 40.6-46.7 23.6 0 39.9 17.1 39.9 46.7v1.5z" />
      <path id="v" fill="currentColor"
        d="M205 23.5h-38c-3.4 0-6.8 2.7-8 7.6l-39 153.8c-1.5 7.2-1.7 9.5-4.7 9.5s-3.2-2.3-4.7-9.5l-39-153.8c-1.1-4.9-4.2-7.6-7.6-7.6H26c-3.4 0-6.1 2.7-6.1 6.1 0 .8 0 1.9.4 2.7L77.4 215c3.6 12.9 18.5 25.5 37.9 25.5s34.9-13.2 38.4-25.5l57-182.7c.4-.8.4-1.9.4-2.7a6 6 0 00-6.1-6.1z" />
      </svg>
  </a>
</mat-toolbar>
